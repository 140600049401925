@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  src: url("./fonts/IBMPlexSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 200;
  src: url("./fonts/IBMPlexSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  src: url("./fonts/IBMPlexSans-SemiBold.ttf") format("truetype");
}

* {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

#feeder-label > div {
  display: flex;
  justify-content: space-between;
}

#feeder-label > div > *{
  padding: 0 5px
}

.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-top-left, .mapboxgl-ctrl-top-right{
  z-index: 0;
}

.maplibre-popup, .mapboxgl-popup {
  font-size: 16px;
}

.PVmarker{
  cursor: pointer;
}